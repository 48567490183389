const provincialCitiesMap = {
    'Białystok': 'bialystok',
    'Bydgoszcz': 'bydgoszcz',
    'Gdańsk': 'gdansk',
    'Gorzów Wielkopolski': 'gorzow-wielkopolski',
    'Katowice': 'katowice',
    'Kielce': 'kielce',
    'Kraków': 'krakow',
    'Lublin': 'lublin',
    'Łódź': 'lodz',
    'Olsztyn': 'olsztyn',
    'Opole': 'opole',
    'Poznań': 'poznan',
    'Rzeszów': 'rzeszow',
    'Szczecin': 'szczecin',
    'Toruń': 'torun',
    'Warszawa': 'warszawa',
    'Wrocław': 'wroclaw',
    'Zielona Góra': 'zielona-gora',
}

export default provincialCitiesMap;
